<template>
  <div class="product-tile relative" :class="[{'show-rating': showRating}]">
    <div class="favourite absolute top-8 right-8 flex flex-row items-center cursor-pointer z-20 text-white" @click="toggleFavourite">
      <span v-if="!favouriteLoading">
        <span v-if="isFavourite" class="text-primary-500">
          <svg width="15" height="15" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="heart" class="svg-inline--fa fa-heart fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M462.3 62.6C407.5 15.9 326 24.3 275.7 76.2L256 96.5l-19.7-20.3C186.1 24.3 104.5 15.9 49.7 62.6c-62.8 53.6-66.1 149.8-9.9 207.9l193.5 199.8c12.5 12.9 32.8 12.9 45.3 0l193.5-199.8c56.3-58.1 53-154.3-9.8-207.9z"></path></svg>
        </span>
        <span v-if="!isFavourite">
          <svg width="15" height="15" aria-hidden="true" focusable="false" data-prefix="far" data-icon="heart" class="svg-inline--fa fa-heart fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M458.4 64.3C400.6 15.7 311.3 23 256 79.3 200.7 23 111.4 15.6 53.6 64.3-21.6 127.6-10.6 230.8 43 285.5l175.4 178.7c10 10.2 23.4 15.9 37.6 15.9 14.3 0 27.6-5.6 37.6-15.8L469 285.6c53.5-54.7 64.7-157.9-10.6-221.3zm-23.6 187.5L259.4 430.5c-2.4 2.4-4.4 2.4-6.8 0L77.2 251.8c-36.5-37.2-43.9-107.6 7.3-150.7 38.9-32.7 98.9-27.8 136.5 10.5l35 35.7 35-35.7c37.8-38.5 97.8-43.2 136.5-10.6 51.1 43.1 43.5 113.9 7.3 150.8z"></path></svg>
        </span>
      </span>
      <span v-else>
        <favourite-loading-spinner />
      </span>
    </div>
    <router-link :to="`${url}${product.elements.slug.value}`" class="block product-inner bg-grey-100 pl-12 pb-12 lg:pl-20 lg:pb-20 relative z-10">
      <div class="image">
        <div class="image-outer-container bg-white bg-opacity-10 pr-12 pt-12 pb-12">
          <div class="image-inner-container max-w-bottle m-auto px-4">
            <div class="relative">
<!--              <div class="mep-badge" v-if="product.elements.mep && product.elements.mep.value.length && product.elements.mep.value[0].codename === 'yes'">-->
<!--                <img src="@/assets/mep-badge-small.png" alt="">-->
<!--              </div>-->
              <div class="image-container relative pb-bottle">
                <img :src="`${product.elements.bottle_shot.value[0].url}?w=200&auto=format`" :alt="product.elements.name.value" class="block absolute left-0 top-0 w-full h-full object-contain max-w-none" v-if="product.elements.bottle_shot.value.length">
              </div>
            </div>
          </div>

        </div>
      </div>
      <div class="brand mt-12 font-bold uppercase text-primary-500 pr-8" v-if="product.elements.brand___range_name.value">{{product.elements.brand___range_name.value}}</div>
      <div class="title mt-2 text-18 font-bold pr-8 leading-2">{{product.elements.name.value}}</div>
      <div class="region mt-4 opacity-50 pr-8" v-if="product.elements.label_claim.value.length">
        {{product.elements.label_claim.value}}
<!--        <span v-if="product.elements.state__zone__region.value && product.elements.state.value">, </span>-->
<!--        <span class="uppercase" v-if="product.elements.state.value">{{product.elements.state.value}}</span>-->
      </div>
    </router-link>
    <star-rating v-if="showRating"
                 :star-size="20"
                 @rating-selected ="setRating"
                 v-model="rating"
                 :show-rating="false"
                 inactive-color="#9A9A9A"
                 border-color="#9A9A9A"
                 active-color="#E8490F"
                 active-border-color="#E8490F"
                 :clearable="true"
    />
  </div>
</template>

<script>
import FavouriteLoadingSpinner from "@/components/FavouriteLoadingSpinner";
import Bus from "@/events/EventBus";
import api from '@/api'
import StarRating from 'vue-star-rating'
export default {
  name: 'ProductTile',
  components: {FavouriteLoadingSpinner, StarRating},
  props: {
    product: {
      type: Object,
      default () {
        return {}
      }
    },
    index: {
      type: Number,
      default: 0
    },
    showRating: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isFavourite () {
      return this.$store.state.favouriteProducts.includes(this.product.elements.product_id.value ? this.product.elements.product_id.value : '0')
    },
    // favouriteLoading () {
    //   // return this.$store.state.favouriteProductsLoading
    //   return false
    // }
    url () {
      return this.product.system.type === 'regional_product' ? '/expo/products/regional/' : '/expo/products/'
    }
  },
  methods: {
    toggleFavourite () {
        if (this.$auth.isAuthenticated) {
            this.favouriteLoading = true
            if (this.isFavourite) {
                this.$store.dispatch('removeFavouriteProduct', this.product.elements.product_id.value ? this.product.elements.product_id.value : '0')
            } else {
                this.$store.dispatch('addFavouriteProduct', this.product.elements.product_id.value ? this.product.elements.product_id.value : '0')
            }
        } else {
            this.showPopup()
        }

    },
    setRating: function(rating){
      this.ratingLoading = true
      api.setProductRating(this.product.elements.product_id.value, rating).then(() => {
        this.ratingLoading = false
        this.$toasted.show("Saved rating", {
          theme: "toasted-primary",
          position: "top-right",
          duration : 5000
        });
      })
    },
    getRating () {
      this.ratingLoading = true
      api.getProductRating(this.product.elements.product_id.value).then((res) => {
        this.rating = res.data.rating
        this.ratingLoading = false
      })
    },
      showPopup() {
          Bus.$emit('show-locked-content-popup')
      }
  },
  data () {
    return {
      favouriteLoading: false,
      rating: 0
    }
  },
  mounted() {
    Bus.$on('favourite-products', () => {
      this.favouriteLoading = false
    })
    if (this.showRating) {
      this.getRating()
    }
  },
  beforeDestroy() {
    Bus.$off('favourite-products')
  }
}
</script>

<style scoped lang="scss">
.brand {
  letter-spacing: 0.15rem;
}
.favourite {
  svg {
    -webkit-filter: drop-shadow( 1px 1px 2px rgba(0, 0, 0, .7));
    filter: drop-shadow( 1px 1px 2px rgba(0, 0, 0, .7));
  }
}

.show-rating {
  .product-inner {
    padding-bottom: 60px !important;
    @media(min-width: 1024px)
    {
      padding-bottom: 60px !important;
    }
  }
  .vue-star-rating {
    position: absolute;
    bottom: 20px;
    z-index: 100;
    left: 30px;
    @media(min-width: 1024px)
    {
      left: 50px;
    }
  }
}
.mep-badge {
  position: absolute;
  right: -20px;
  top: 40px;
  z-index: 5;
  img {
    width: 70px;
    height: 70px;
    display: block;
  }
}
</style>
